import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Hero2 from "components/hero/Hero-Unrooting";
import Features from "components/features/Features-Unrooting";
import MainFeature from "components/features/Features2-Unrooting.js";
import MainFeature2 from "components/features/Features2-Unrooting";
import FeatureWithSteps from "components/features/Features3-Unrooting";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter";
import heroScreenshotImageSrc from "images/spellbook-2.png";
import spellcast1 from "images/spellcast-1.png";
import spellcast2 from "images/spellcast-2.png";
import featureImage1 from "images/uf-feature-1.jpg";
import featureImage2 from "images/uf-feature-2.jpg";
import featureImage3 from "images/uf-feature-3.jpg";
import augmentedReality from "images/talking-trees.png";
import featureImage4 from "images/uf-feature-4.jpg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import TeamFeature1 from "components/features/UF-team-1.js";
import TeamFeature2 from "components/features/UF-team-2.js";
export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-green-700`;
  const HighlightedText = tw.span`text-green-700`;

  return (
    <AnimationRevealPage >
      <Hero2 roundedHeaderButton={true} />
      <TeamFeature1 />
      <TeamFeature2 />
 
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
