import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AtmosHero";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/Features2-Unrooting.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter";
import heroScreenshotImageSrc from "images/spellbook-2.png";
import spellcast1 from "images/spellcast-1.png";
import spellcast2 from "images/spellcast-2.png";
import atmosFeatures1 from "images/atmos-features-1.png";
import atmosFeatures2 from "images/atmos-features-2.png";
import atmosFeatures3 from "images/atmos-features-3.png";
import atmosEasyToUse from "images/atmos-easytouse-2.png";
import augmentedReality from "images/talking-trees.png";
import macHeroScreenshotImageSrc from "images/spellbook-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Feature1 from 'components/features/AG8-Features-1'
import AG8Features1 from "components/features/AG8-Features-1";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-green-700`;
  const HighlightedText = tw.span`text-green-700`;

  return (
    <AnimationRevealPage >
      <Hero roundedHeaderButton={true} />
     
      <AG8Features1 />
     
      <Features
        subheading={<Subheading>For we are one,</Subheading>}
        heading={
          <>
            We Cover <HighlightedText>Indie Devs!</HighlightedText>
          </>
        }
      />

   

<MainFeature
        subheading={<Subheading>Get ready for</Subheading>}
        imageSrc={atmosFeatures1}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
        heading={"Dynamic Environment"}
        description={"Atmos will back you up when it comes to art critisation. It will be dynamic, it can be abstract and it's generative!"}
      />
      <MainFeature
        subheading={<Subheading>Plug your mouse &</Subheading>}
        imageSrc={atmosFeatures2}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={true}
        heading={"Drag & Drop it"}
        description={"First do it for our manager component. Then bring out your favourite sprites! It will work like a magic."}
      />
      <MainFeature
        subheading={<Subheading>Create a new project</Subheading>}
        imageSrc={atmosFeatures3}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
        heading={"Import it"}
        description={"Your next Unity project will welcome Atmos. You can use it with any project you like."}
      />
      
      {/* <FeatureWithSteps
        subheading={<Subheading>It is</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Use</HighlightedText>
          </>
        }
        textOnLeft={true}
        imageSrc={atmosEasyToUse}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      /> */}








      {/* <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      /> */}
      {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
          }
        ]}
      /> */}

      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      /> */}
      <FAQ
        subheading={<Subheading>Oh!</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        // faqs={[
        //   {
        //     question: "Are all the templates easily customizable ?",
        //     answer:
        //       "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        //   },
        //   {
        //     question: "How long do you usually support an standalone template for ?",
        //     answer:
        //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        //   },
        //   {
        //     question: "What kind of payment methods do you accept ?",
        //     answer:
        //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        //   },
        //   {
        //     question: "Is there a subscribption service to get the latest templates ?",
        //     answer:
        //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        //   },
        //   {
        //     question: "Are the templates compatible with the React ?",
        //     answer:
        //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        //   },
        //   {
        //     question: "Do you really support Internet Explorer 11 ?",
        //     answer:
        //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        //   }
        // ]}
      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
