import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Hero2 from "components/hero/Hero-Buried";
import Features from "components/features/Features-Buried";
import MainFeature from "components/features/Features2-Buried";
import MainFeature2 from "components/features/Features2-Buried";
import FeatureWithSteps from "components/features/Features3-Unrooting";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted-Buried";
import Footer from "components/footers/UF-Footer";
import heroScreenshotImageSrc from "images/spellbook-2.png";
import spellcast1 from "images/spellcast-1.png";
import spellcast2 from "images/spellcast-2.png";
import featureImage1 from "images/buried-feature-1.jpg";
import featureImage2 from "images/buried-feature-2.jpg";
import featureImage3 from "images/buried-feature-3.jpg";
import augmentedReality from "images/talking-trees.png";
import featureImage4 from "images/uf-feature-4.jpg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-blue-700`;
  const HighlightedText = tw.span`text-blue-700`;

  return (
    <AnimationRevealPage >
      <Hero2 roundedHeaderButton={true} />
      {/* <Hero /> */}
      <Features
        subheading={<Subheading>Didn't you expect an uprising? They did...</Subheading>}
        heading={
          <>
            and this story covers that <HighlightedText>epic rebellion against Erlik</HighlightedText>
          </>
        }
      />

      <MainFeature
        subheading={<Subheading>First</Subheading>}
        imageSrc={featureImage1}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
        heading={"Remember the oldies"}
        description={"And, you should realise you were a great spaceship commander back in time. Space Shooter god damn it! You will enjoy space shooter with modern touches and an epic story."}
      />
      <MainFeature
        subheading={<Subheading>Then</Subheading>}
        imageSrc={featureImage2}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={true}
        heading={"Warm up your pilot skills"}
        description={"You should enjoy it at some points. It shouldn't take too long to figure it out. Just enjoy the fucking moment. You are sailing a spaceship!"}
      />
      <MainFeature
        subheading={<Subheading>Don't Blink &</Subheading>}
        imageSrc={featureImage3}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
        heading={"Get lost in the very linear story of The Buried Light"}
        description={"They say non-linear storytelling is way better. But, where's punk when you do everything as they say?"}
      />
      
      <FeatureWithSteps
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Consume</HighlightedText> & Hard to Master??
          </>
        }
        textOnLeft={true}
        imageSrc={featureImage4}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
     
      <FAQ
        subheading={<Subheading>Oh!</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }

      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
