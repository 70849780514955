import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/star-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-green-700 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-green-700 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-green-700`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Games",
      description: "We create more than mere distractions; these are digital odysseys designed to make you wonder why you ever liked reality. Brace yourself for epic adventures that might just make your real-life responsibilities wait."
    },
    { imageSrc: SupportIconImage, title: "Web Development", description:"We build stunning, responsive websites that promise to occasionally crash less than your hopes and dreams. Prepare for a digital experience almost as compelling as your daily coffee." },
    { imageSrc: CustomizeIconImage, title: "Multimedia Design", description: "We merge graphics, sound, and video to craft experiences that are sure to distract you from life's mundane moments. Our designs don't just catch your eye; they practically demand a staring contest."},
    { imageSrc: ReliableIconImage, title: "Transmedia Narrative", description:"Because why tell a story on one platform when you can complicate things and use five? We weave tales across various media, ensuring you can never escape our storytelling, no matter how hard you try." },
    { imageSrc: FastIconImage, title: "Digital Marketing", description:"Digital Marketing, or how to make people click on things they didn't know they needed. We use SEO and social media to reach audiences far and wide, employing strategies so effective, they'd convince a hermit to go shopping online." },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>We like to do it <span tw="text-green-700">Holistic</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              {/* <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span> */}
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
