import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Hero2 from "components/hero/Hero-Prophecy";
import Features from "components/features/Features-Prophecy";
import MainFeature from "components/features/Features2-Prophecy";
import MainFeature2 from "components/features/Features2-Prophecy";
import FeatureWithSteps from "components/features/Features3-Unrooting";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted-Prophecy";
import Footer from "components/footers/UF-Footer";
import heroScreenshotImageSrc from "images/spellbook-2.png";
import spellcast1 from "images/spellcast-1.png";
import spellcast2 from "images/spellcast-2.png";
import featureImage1 from "images/pu-feature-1.jpg";
import featureImage2 from "images/pu-feature-2.jpg";
import featureImage3 from "images/pu-feature-3.jpg";
import augmentedReality from "images/talking-trees.png";
import featureImage4 from "images/uf-feature-4.jpg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-purple-700`;
  const HighlightedText = tw.span`text-purple-700`;

  return (
    <AnimationRevealPage >
      <Hero2 roundedHeaderButton={true} />
      {/* <Hero /> */}
      <Features
        subheading={<Subheading>Did you realise</Subheading>}
        heading={
          <>
            there was no hero <HighlightedText>in the heroic myth?</HighlightedText>
          </>
        }
      />

      <MainFeature
        subheading={<Subheading>First</Subheading>}
        imageSrc={featureImage1}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
        heading={"Get your spellbook"}
        description={"And, you should become a caster in any moment. Because, the tome, spellbook itself is something magical!"}
      />
      <MainFeature
        subheading={<Subheading>Then</Subheading>}
        imageSrc={featureImage2}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={true}
        heading={"Find your spell to solve the issue"}
        description={"You should enjoy it at some points. It shouldn't take too long to figure it out. Just enjoy the fucking moment. You are casting!"}
      />
      <MainFeature
        subheading={<Subheading>Don't Blink &</Subheading>}
        imageSrc={featureImage3}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
        heading={"Get lost in the very linear story of Prophecy Untold"}
        description={"They say non-linear storytelling is way better. But, where's punk when you do everything as they say?"}
      />
      
      <FeatureWithSteps
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Consume</HighlightedText> & Hard to Master??
          </>
        }
        textOnLeft={true}
        imageSrc={featureImage4}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
     
      <FAQ
        subheading={<Subheading>Oh!</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }

      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
