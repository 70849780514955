import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import backgroundImage from 'images/prophecy-untold-cover.jpg'

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-green-700`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("${backgroundImage}");
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-green-700 text-gray-100 hocus:bg-red-500 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;



export default () => {
 

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        {/* <StyledHeader links={navLinks} /> */}
        <Content>
          <Heading>
              Prophecy Untold
              <br />
              <p style={{fontSize:"1.2rem"}}>Spell Casting Simulation RPG</p>
          </Heading> 
          <a href="/prophecy-untold">
            <PrimaryAction>Coming Soon</PrimaryAction>
          </a>
        </Content>
      </HeroContainer>
    </Container>
  );
};
