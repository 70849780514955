import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Hero2 from "components/hero/Hero-Unrooting";
import Features from "components/features/Features-Unrooting";
import MainFeature from "components/features/Features2-Unrooting.js";
import MainFeature2 from "components/features/Features2-Unrooting";
import FeatureWithSteps from "components/features/Features3-Unrooting";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/UF-Footer";
import heroScreenshotImageSrc from "images/spellbook-2.png";
import spellcast1 from "images/spellcast-1.png";
import spellcast2 from "images/spellcast-2.png";
import featureImage1 from "images/uf-feature-1.jpg";
import featureImage2 from "images/uf-feature-2.jpg";
import featureImage3 from "images/uf-feature-3.jpg";
import augmentedReality from "images/talking-trees.png";
import featureImage4 from "images/uf-feature-4.jpg";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Feature1 from 'components/features/UF-Features-1'

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-green-700`;
  const HighlightedText = tw.span`text-green-700`;

  return (
    <AnimationRevealPage >
      <Hero2 roundedHeaderButton={true} />

      {/* <Hero /> */}
      <Features
        subheading={<Subheading>Did you know that</Subheading>}
        heading={
          <>
            each tree represents <HighlightedText>a human?</HighlightedText>
          </>
        }
      />

      

      <MainFeature
        subheading={<Subheading>First</Subheading>}
        imageSrc={featureImage1}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
        heading={"Get your attention catched"}
        description={"And, you should think of the potential stories, and surreal expressionism ideas. But we used 'realistic' as a prompt. Because, these projected post-apocalyptic trees are no joke!"}
      />
      <MainFeature
        subheading={<Subheading>Then</Subheading>}
        imageSrc={featureImage2}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={true}
        heading={"Find a way to watch the cinematic"}
        description={"You should enjoy it at some points. It should take approximately 5 minutes to consume."}
      />
      <MainFeature
        subheading={<Subheading>Don't Blink &</Subheading>}
        imageSrc={featureImage3}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={false}
        heading={"Get lost in the expanding nature of transmedia"}
        description={"Realities are in high demand these days. So, make sure you got enough realities before we run out of stocks! Or, follow your questions and you will see your answers be carved in the very best matching medium."}
      />
      
      <FeatureWithSteps
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Consume</HighlightedText> & Hard to Master??
          </>
        }
        textOnLeft={true}
        imageSrc={featureImage4}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
     
      <FAQ
        subheading={<Subheading>Oh!</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }

      />
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
